import formStore from 'stores/Form';
import Pagination from 'components/Pagination';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

type Props = {
  customerId: string;
  collaboratorId?: string;
};

const FormCustomerFinish = (props: Props) => {
  const navigate = useNavigate();
  const { customerId, collaboratorId } = props;
  const { currentForm } = formStore;

  if (!currentForm) return null;

  return (
    <div>
      <h3 className="mt-20 text-4xl font-bold">Félicitations !</h3>
      <p className="mt-20 mb-20">
        <span className="font-normal">
          Notre équipe étudie votre demande et vous recontactera dans les plus brefs délais.
        </span>
      </p>
      <button
        type="button"
        onClick={() => {
          formStore.reset(currentForm.stepsTotal);
          navigate(`/forms/${collaboratorId ? `collaborators/${collaboratorId}` : customerId}`);
        }}
        className="text-green"
      >
        <span className="font-normal flex flex-row items-center justify-between">
          <ArrowLeftIcon className="inline-block w-5 h-5 mr-2" />
          Retour au sommaire
        </span>
      </button>
      <Pagination customerId={customerId} collaboratorId={collaboratorId} />
    </div>
  );
};

export default FormCustomerFinish;
