type Props = {
  setValueInput: (value: string) => void;
  defaultValue: string | number | readonly string[] | undefined;
  placeholder: string;
  type: string;
};

const FormCustomerStepInput = (props: Props) => {
  const { setValueInput, defaultValue, placeholder, type } = props;

  return (
    <input
      type={type}
      className="mt-10 p-4 w-full border border-gray-300 rounded-md"
      placeholder={placeholder}
      value={defaultValue}
      onChange={(event) => {
        setValueInput(event.target.value);
      }}
    />
  );
};

export default FormCustomerStepInput;
