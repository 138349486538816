import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import Home from 'pages/Home';
import FormCustomer from 'pages/FormCustomer';
import FormCollaborator from 'pages/FormCollaborator';
import Footprint from 'pages/Footprint';

const AppRoutes = (): JSX.Element => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/forms/:customerId" element={<FormCustomer />} />
      <Route path="/forms/:customerId/:stepPosition" element={<FormCustomer />} />
      <Route path="/forms/collaborators/:collaboratorId" element={<FormCollaborator />} />
      <Route
        path="/forms/collaborators/:collaboratorId/:stepPosition"
        element={<FormCollaborator />}
      />
      <Route path="/footprint/:customerId" element={<Footprint />} />
    </Routes>
  </Router>
);

export default observer(AppRoutes);
