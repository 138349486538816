import formStore from 'stores/Form';

type Props = {
  totalTime: number;
};

const EstimatedTimeBar = (props: Props) => {
  const { totalTime } = props;
  const { currentForm } = formStore;
  if (!currentForm) return null;

  const seconds = totalTime ?? 30;

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

  return (
    <div className="w-60">
      {(hours > 0 || minutes > 0 || seconds > 0) && (
        <p>
          Temps estimé:{' '}
          {hours > 0 && <span className="text-green font-normal">{formattedHours}h</span>}{' '}
          {minutes > 0 && <span className="text-green font-normal">{formattedMinutes}min</span>}{' '}
          {remainingSeconds > 0 && (
            <span className="text-green font-normal">{formattedSeconds}sec</span>
          )}
        </p>
      )}
    </div>
  );
};

export default EstimatedTimeBar;
