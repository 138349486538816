import {
  ArrowUpOnSquareIcon,
  ExclamationTriangleIcon,
  PlusIcon
} from '@heroicons/react/24/outline';
import { Answer } from 'queryTypes/answer';
import { Question } from 'queryTypes/question';
import { useCallback, useEffect, useState, useMemo } from 'react';
import FormCustomerStepSheetTable from './Table';
import config from 'config';
import axios from 'axios';

export type SheetValues = {
  name: string;
  type: 'text' | 'number' | 'multiple_choices';
  possibleAnswers: { label: string; value: string }[];
};

export type SheetAnswers = {
  column: string;
  value: string;
};

type Props = {
  setValueInput: (value: string) => void;
  answer: Answer | undefined;
  question: Question;
  setUrlCsvFile: (value: string) => void;
  urlCsvFile: string | undefined;
};

const FormCustomerStepSheet = (props: Props) => {
  const { setValueInput, answer, question, setUrlCsvFile, urlCsvFile } = props;

  const arrayJSON = useMemo(
    () => (question.sheetTemplate ? JSON.parse(question?.sheetTemplate) : null),
    [question]
  );
  const arrayAnswerJSON = useMemo(
    () => (answer?.sheet ? JSON.parse(answer?.sheet) : null),
    [answer]
  );

  const handleUploadS3 = useCallback((file: File | null) => {
    if (!file) return;
    const formData = new FormData();
    formData.append('file', file);

    axios.post(`${config.API_URL}/uploadToS3`, formData).then((res) => {
      const { data } = res;
      setUrlCsvFile(data.key);
    });
  }, []);

  const [sheetTemplate, setSheetTemplate] = useState<SheetAnswers[][]>([]);

  const handleChangeSheetTemplate = useCallback(
    (value: string, index: number, indexValue: number) => {
      setSheetTemplate((prevState) => {
        const newState = [...prevState];
        if (newState[index][indexValue]) {
          newState[index][indexValue].value = value;
        } else {
          newState[index][indexValue] = { column: `Column ${indexValue}`, value };
        }
        return newState;
      });
    },
    []
  );

  const handleAddSheetTemplate = useCallback(() => {
    setSheetTemplate((prevState) => [
      ...prevState,
      arrayJSON?.columns?.map((sheet: SheetValues) => {
        return {
          column: sheet.name,
          value:
            arrayAnswerJSON?.find((answer: SheetAnswers) => answer.column === sheet.name)?.value ??
            ''
        };
      })
    ]);
  }, [arrayJSON, arrayAnswerJSON]);

  const handleDeleteSheetTemplate = useCallback(
    (index: number) => {
      setSheetTemplate((prevState) => {
        const newState = [...prevState];
        newState.splice(index, 1);
        return newState;
      });
    },
    [sheetTemplate]
  );

  useEffect(() => {
    setSheetTemplate(
      arrayAnswerJSON ?? [
        arrayJSON?.columns?.map((sheet: SheetValues) => {
          return {
            column: sheet.name,
            value:
              arrayAnswerJSON?.find((answer: SheetAnswers) => answer.column === sheet.name)
                ?.value ?? ''
          };
        })
      ]
    );
  }, [arrayJSON, arrayAnswerJSON]);

  useEffect(() => {
    setValueInput(JSON.stringify(sheetTemplate));
  }, [sheetTemplate, setValueInput, arrayJSON, arrayAnswerJSON]);

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
        <div className="inline-block max-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="flex justify-end space-x-5">
            <button
              type="button"
              className="flex justify-between items-center space-x-3 rounded-full px-5 bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleAddSheetTemplate}
            >
              <PlusIcon className="h-7 w-7" />
              <p>Ajouter une ligne</p>
            </button>
            <label
              htmlFor="file-upload"
              className="flex justify-between items-center space-x-3 px-5 p-2 text-indigo-600 cursor-pointer"
            >
              <ArrowUpOnSquareIcon className="h-7 w-7" />
              <p>Importer un fichier Excel</p>
            </label>
            <input
              id="file-upload"
              type="file"
              className="hidden"
              onChange={(e) => handleUploadS3(e.target.files ? e.target.files[0] : null)}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
          {urlCsvFile && (
            <div className="rounded-md bg-yellow-50 p-4 mt-10">
              <div className="flex">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-yellow-800">Attention</h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    <p>Vous avez déjà upload un fichier nommé : {urlCsvFile}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="overflow-auto">
            <FormCustomerStepSheetTable
              onChangeSheetTemplate={handleChangeSheetTemplate}
              onDeleteSheetTemplate={handleDeleteSheetTemplate}
              sheetQuestion={arrayJSON?.columns}
              sheetAnswer={sheetTemplate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormCustomerStepSheet;
