type Props = {
  setValueInput: (value: string) => void;
  defaultValue: string | number | readonly string[] | undefined;
};

const FormCustomerStepBoolean = (props: Props) => {
  const { setValueInput, defaultValue } = props;

  return (
    <div className="mt-10 flex flex-row justify-start items-center space-x-4">
      <button
        type="button"
        className={`px-10 py-4 font-medium border border-gray-300  hover:bg-green hover:text-white uppercase w-full rounded-md ${
          'oui' === defaultValue ? 'bg-green text-white' : 'bg-white text-black'
        }`}
        onClick={() => {
          setValueInput('oui');
        }}
      >
        Oui
      </button>
      <button
        type="button"
        className={`px-10 py-4 font-medium border border-gray-300  hover:bg-green hover:text-white uppercase w-full rounded-md ${
          'non' === defaultValue ? 'bg-green text-white' : 'bg-white text-black'
        }`}
        onClick={() => {
          setValueInput('non');
        }}
      >
        Non
      </button>
    </div>
  );
};

export default FormCustomerStepBoolean;
