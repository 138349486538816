import formStore from 'stores/Form';
import {
  ArrowRightIcon,
  BellAlertIcon,
  CheckBadgeIcon,
  ClockIcon,
  CloudArrowUpIcon,
  KeyIcon,
  PrinterIcon
} from '@heroicons/react/24/outline';
import { Question } from 'queryTypes/question';
import { Answer } from 'queryTypes/answer';
import { Link, useNavigate } from 'react-router-dom';

type Props = {
  customerId: string;
  questions?: Question[];
  collaboratorId?: string;
};

const FormCustomerIntro = (props: Props) => {
  const navigate = useNavigate();
  const { customerId, questions, collaboratorId } = props;
  const { currentForm } = formStore;

  if (!currentForm) return null;

  return (
    <div className="flex flex-col lg:flex-row justify-between items-start px-6 lg:px-0 mt-10 space-x-4">
      <div className="mx-auto w-full lg:mx-0 lg:max-w-none flex flex-col space-y-4">
        <div>
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Votre bilan carbone optimisé
              </h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
                Nous vous avons préparé un questionnaire optimisé et exclusif à votre secteur
                d&apos;activité.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
                Votre questionnaire est composé de {currentForm.stepsTotal} question(s), vous pouvez
                à tout moment quitter cette page et revenir plus tard.
              </p>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CloudArrowUpIcon
                    className="mt-1 h-5 w-5 flex-none text-green"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Toutes vos réponses sont sauvegardées après avoir cliqué sur le bouton
                      &quot;Suivant&quot;,
                    </strong>{' '}
                    vous pouvez à tout moment quitter cette page et revenir plus tard
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <ClockIcon className="mt-1 h-5 w-5 flex-none text-green" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Soyez le plus précis possible,
                    </strong>{' '}
                    prenez votre temps
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CheckBadgeIcon
                    className="mt-1 h-5 w-5 flex-none text-green"
                    aria-hidden="true"
                  />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Lorsque vous avez terminé,
                    </strong>{' '}
                    assurez-vous que toutes vos informations sont correctes
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <KeyIcon className="mt-1 h-5 w-5 flex-none text-green" aria-hidden="true" />
                  <span>
                    Toutes vos données sont{' '}
                    <strong className="font-semibold text-gray-900">sécurisées</strong>
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <BellAlertIcon className="mt-1 h-5 w-5 flex-none text-green" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">
                      Vous serez automatiquement avertis
                    </strong>{' '}
                    lorsque votre Bilan carbone sera validé
                  </span>
                </li>
              </ul>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                Vous êtes prêts ?
              </h2>
              <p className="mt-6">
                Si vous souhaitez débuter le questionnaire de votre Bilan carbone, cliquez sur
                &quot;Je suis prêt&quot;
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl flex flex-row justify-between items-center">
          Sommaire <PrinterIcon className="h-6 cursor-pointer" onClick={() => window.print()} />
        </h1>
        <div className="summary mt-10 overflow-auto h-[30rem] p-4 scroll-smooth">
          <ul className="grid grid-cols-3 gap-6">
            {questions?.map((question: Question, index: number) => {
              const answer =
                customerId && !collaboratorId
                  ? question?.answers?.find((answer) => answer.customerId === customerId)
                  : question?.answers?.find((answer) => answer.collaboratorId === collaboratorId);

              let checkAnswer = false;
              const typeAnswer = answer?.sheet && answer?.sheet?.length > 0 ? 'sheet' : 'value';

              if (typeAnswer === 'value') {
                checkAnswer = answer?.value && answer?.value?.length > 0 ? true : false;
              } else {
                if (answer?.sheet && answer?.sheet?.length <= 0) {
                  checkAnswer = false;
                }

                const answerSheetValues = JSON.parse(answer?.sheet as string);
                const nbAnswerSheetValues = answerSheetValues.length + 1;
                let checkAnswerSheet = 0;
                answerSheetValues[0].forEach((answerSheetValue: { label: string; value: any }) => {
                  if (answerSheetValue.value && answerSheetValue.value?.length > 0) {
                    checkAnswerSheet += 1;
                  } else {
                    checkAnswerSheet -= 1;
                  }
                });

                if (checkAnswerSheet === nbAnswerSheetValues) {
                  checkAnswer = true;
                } else {
                  checkAnswer = false;
                }
              }

              return (
                <li key={question.id} className="row-auto">
                  <Link
                    to={`/forms/${
                      collaboratorId ? `collaborators/${collaboratorId}` : customerId
                    }/${index + 1}`}
                    className="hover:text-green"
                  >
                    {checkAnswer && <CheckBadgeIcon className="inline-flex w-5 h-5 text-green" />}{' '}
                    {index + 1}. {question.text}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="lg:fixed lg:bottom-2 mt-5 mb-5 lg:right-0 lg:px-20 px-10 w-96">
        <button
          type="button"
          className="flex items-center px-10 py-4 text-white bg-green rounded-full uppercase"
          onClick={() => {
            formStore.update(1);
            navigate(`/forms/${collaboratorId ? `collaborators/${collaboratorId}` : customerId}/1`);
          }}
        >
          Je suis prêt
          <ArrowRightIcon className="inline-block w-5 h-5 ml-2" />
        </button>
      </div>
    </div>
  );
};

export default FormCustomerIntro;
