import { gql } from '@apollo/client';
import { FilterCollaborator } from 'queryTypes/collaborator';

export const useGetAllCollaboratorsPaginated = (options?: FilterCollaborator) => gql`
  query getCollaboratorsPaginated {
    paginatedCollaborators(page: ${options?.page}, perPage: ${options?.per_page}, filter: "${
  options?.name ? `customers.name % '${options?.name}'` : ``
}", ${
  options?.orderByField ? `, orderBy: "${options?.orderByField} ${options?.orderByDirection}"` : ``
}) {
    page
    perPage
    totalCount
    data {
      id
      name
      role
      answers {
        id
        question {
          id
          text
        }
        value
        sheet
        csvUrl
        customerId
        collaboratorId
      }
      customers {
        id
        name
        email
        forms {
          id
          questions {
            id
            text
            orderIndex
            answerType
            estimatedTimeToAnswer
            sheetFormula
            sheetTemplate
            possibleAnswers {
              id
              value
              label
            }
            answers {
              id
              value
              sheet
              customerId
              collaboratorId
              csvUrl
            }
            excelMappings {
              id
              excelBaseColumn
              excelBaseLine
              excelTab
              insert
              orderIndex
              value
            }
            collaborators {
              id
              name
              role
            }
          }
        }
      }
    }
  }
}
`;

export const useGetAllCollaborators = (options?: FilterCollaborator) => gql`
  query Collaborators {
    collaborators(filter: "${
      options?.customerId ? `customer_id == '${options?.customerId}'` : ``
    }") {
    id
    name
    role
    answers {
      id
      question {
        id
        text
      }
      value
      sheet
      csvUrl
    }
    customers {
      id
      name
      email
      forms {
        id
        questions {
          id
          text
          orderIndex
          answerType
          estimatedTimeToAnswer
          sheetFormula
          sheetTemplate
          possibleAnswers {
            id
            value
            label
          }
          answers {
            id
            value
            sheet
            customerId
            collaboratorId
            csvUrl
          }
          excelMappings {
            id
            excelBaseColumn
            excelBaseLine
            excelTab
            insert
            orderIndex
            value
          }
          collaborators {
            id
            name
            role
          }
        }
      }
    }
  }
}
`;

export const useGetCollaborator = (options?: FilterCollaborator) => gql`
  query getCollaborator {
    collaborator(id : "${options?.id}") {
      id
      name
      role
      answers {
        id
        question {
          id
          text
        }
        value
        sheet
        csvUrl
      }
      customers {
        id
        name
        avatarUrl
        email
        forms {
          id
          questions {
            id
            text
            orderIndex
            answerType
            estimatedTimeToAnswer
            sheetFormula
            sheetTemplate
            possibleAnswers {
              id
              value
              label
            }
            answers {
              id
              value
              sheet
              customerId
              collaboratorId
              csvUrl
            }
            excelMappings {
              id
              excelBaseColumn
              excelBaseLine
              excelTab
              insert
              orderIndex
              value
            }
            collaborators {
              id
              name
              role
            }
          }
          customers {
            id
            name
            avatarUrl
          }
        }
      }
    }
  }
`;

export const useCreateCollaborator = gql`
  mutation createCollaborator($collaborator: CollaboratorInputType!) {
    createCollaborator(attributes: $collaborator) {
      collaborator {
        id
      }
      errors
    }
  }
`;

export const useUpdateCollaborator = gql`
  mutation updateCollaborator($collaboratorId: String!, $collaborator: CollaboratorInputType!) {
    updateCollaborator(id: $collaboratorId, attributes: $collaborator) {
      collaborator {
        id
      }
      errors
    }
  }
`;

export const useDestroyCollaborator = gql`
  mutation destroyCollaborator($collaboratorId: String!) {
    destroyCollaborator(id: $collaboratorId) {
      collaborator {
        id
      }
      errors
    }
  }
`;
