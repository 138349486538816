import Select from 'components/Select';
import { SheetValues, SheetAnswers } from '../../..';
import ComboBox from 'components/ComboBox';

type Props = {
  onChangeSheetTemplate: (value: string, index: number, indexValue: number) => void;
  sheetQuestion?: SheetValues;
  sheetAnswer: SheetAnswers[];
  index: number;
  indexValue: number;
};

const FormCustomerStepSheetTableLineItem = (props: Props) => {
  const { onChangeSheetTemplate, sheetQuestion, sheetAnswer, index, indexValue } = props;

  if (!sheetQuestion) return null;

  return (
    <td key={index} className="whitespace-nowrap p-4 text-sm text-gray-900">
      {sheetQuestion.type === 'multiple_choices' ? (
        <ComboBox
          selected={
            sheetQuestion.possibleAnswers.find(
              (possibleAnswer: any) => possibleAnswer.value === sheetAnswer[indexValue]?.value
            )?.label
          }
          onChange={(value: any) => onChangeSheetTemplate(value, index, indexValue)}
          options={sheetQuestion.possibleAnswers.map((possibleAnswer: any) => ({
            label: possibleAnswer.label,
            value: possibleAnswer.value
          }))}
        />
      ) : (
        <input
          type={sheetQuestion.type}
          className="w-60 border border-gray-300 rounded-md"
          placeholder="Votre réponse"
          value={sheetAnswer[indexValue]?.value ?? ''}
          onChange={(event: any) => onChangeSheetTemplate(event.target.value, index, indexValue)}
        />
      )}
    </td>
  );
};

export default FormCustomerStepSheetTableLineItem;
