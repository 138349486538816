import formStore from 'stores/Form';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import formService from 'services/api/forms/services';
import Layout from 'layout';
import { Chart as ChartBar, Doughnut } from 'react-chartjs-2';
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineController,
  PointElement,
  LineElement,
  BarController,
  Title
} from 'chart.js';
import Loading from 'components/Loading';

Chart.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  LineController,
  PointElement,
  LineElement,
  BarController
);

const Footprint = () => {
  const { currentForm } = formStore;
  const { customerId } = useParams<{ customerId: string }>();

  // Query forms
  const {
    data: form,
    error,
    refetch,
    loading: isLoading
  } = formService.getAll({
    customerId: customerId
  });

  useEffect(() => {
    if (form && form.forms[0]) {
      formStore.init(form.forms[0].questions.length);
    }
  }, [form]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!customerId) return 'Incorrect form. (customer)';
  if (!currentForm) return 'Incorrect form. (store / form)';
  if (form && !form.forms[0]) return 'Incorrect form. (form)';

  const randomHexColorCode = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  };

  const camembert = useMemo(() => {
    if (!form) return;
    return JSON.parse(form?.forms[0]?.stats[0]?.camembert);
  }, [form]);

  const lengthData = camembert?.data.length;

  const bar = useMemo(() => {
    if (!form) return;
    return JSON.parse(form?.forms[0]?.stats[0]?.bar);
  }, [form]);

  const equivalent = useMemo(() => {
    if (!form) return;
    return JSON.parse(form?.forms[0]?.stats[0]?.equivalent);
  }, [form]);

  const intensity = useMemo(() => {
    if (!form) return;
    return JSON.parse(form?.forms[0]?.stats[0]?.intensity);
  }, [form]);

  const year = useMemo(() => {
    if (!form) return;
    return JSON.parse(form?.forms[0]?.stats[0]?.year);
  }, [form]);

  const dataDoughnut = {
    labels: camembert?.data.map((d: any) => d.label),
    datasets: [
      {
        label: ' Tonnes',
        data: camembert?.data.map((d: any) => d.percent),
        backgroundColor: camembert?.data.map((d: any) => randomHexColorCode()),
        hoverOffset: 4
      }
    ]
  };

  const optionsDoughnut = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as string as 'bottom',
        align: 'center' as string as 'center',
        fullSize: true,
        pointStyle: 'circle' as string as 'circle'
      }
    }
  };

  const pluginsDoughnut = useMemo(() => {
    if (!camembert) return;
    return [
      {
        id: 'custom_canvas_background_color',
        beforeDraw: function (chart: { width: any; height: any; ctx: any }) {
          var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
          ctx.restore();
          var fontSize = (height / 260).toFixed(2);
          ctx.font = fontSize + 'em sans-serif';
          ctx.textBaseline = 'top';

          // Draw "Total" on the first line
          var text1 = camembert?.total;
          var textX1 = Math.round((width - ctx.measureText(text1).width) / 2);
          var ajustement = (lengthData / 2) * 17;
          var textY1 = width / 2 - ajustement;
          ctx.fillText(text1, textX1, textY1);

          // Draw the number on the second line
          var text2 = 'tCO2e';
          var textX2 = Math.round((width - ctx.measureText(text2).width) / 2);
          var textY2 = width / 2 - ajustement + 40;
          ctx.fillText(text2, textX2, textY2);

          ctx.save();
        }
      }
    ];
  }, [camembert]);

  const dataBar = useMemo(() => {
    if (!bar) return;
    return {
      labels: [year.date, year.date + 1, year.date + 5, year.date + 10],
      datasets: [
        {
          type: 'line' as string as 'line',
          label: 'Objectif',
          data: [bar.objectif[2022], bar.objectif[2023], bar.objectif[2027], bar.objectif[2032]],
          backgroundColor: '#FA9D4C'
        },
        {
          type: 'bar' as string as 'bar',
          label: 'Émissions mesurées',
          data: [bar.emission[2022], bar.emission[2023], bar.emission[2027], bar.emission[2032]],
          backgroundColor: '#00AA75'
        },
        {
          type: 'bar' as string as 'bar',
          label: "Scénario sans plan d'action",
          data: [
            bar.scenario_without_action[2022],
            bar.scenario_without_action[2023],
            bar.scenario_without_action[2027],
            bar.scenario_without_action[2032]
          ],
          backgroundColor: '#FBD5D5'
        },
        {
          type: 'bar' as string as 'bar',
          label: "Scénario avec plan d'action",
          data: [
            bar.scenario_with_action[2022],
            bar.scenario_with_action[2023],
            bar.scenario_with_action[2027],
            bar.scenario_with_action[2032]
          ],
          backgroundColor: '#F8F1FF'
        }
      ]
    };
  }, [bar]);

  const optionsBar = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const
      }
    }
  };

  return (
    <>
      <Layout currentForm={form?.forms[0]} customerId={customerId}>
        <div className="bg-[#e6e6e6] h-screen lg:pl-96">
          <div className="bg-[#e6e6e6] py-10 px-4 sm:px-6 lg:px-20">
            <div className="w-full">
              {isLoading ? (
                <Loading />
              ) : (
                <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                  Bilan carbone {year.date}
                </h1>
              )}
              <p className="mt-5">
                Le bilan carbone d&apos;une entreprise mesure toutes les émissions de gaz à effet de
                serre liées à son activité sur une année, regroupées par catégorie. Cet outil permet
                de mesurer l&apos;impact d&apos;une entreprise sur le climat, et d&apos;en
                comprendre les causes. C&apos;est une étape indispensable pour réduire efficacement
                ses émissions.
              </p>
              <div className="w-full flex flex-col justify-center items-center mt-10">
                <div className="w-full bg-white p-5 shadow-md flex flex-col justify-center items-center">
                  <div className=" w-[29rem]">
                    {isLoading ? (
                      <Loading />
                    ) : (
                      <Doughnut
                        data={dataDoughnut}
                        options={optionsDoughnut}
                        plugins={pluginsDoughnut}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <div className="mt-10">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                      <div className="mx-auto max-w-2xl lg:mx-0">
                        <h2 className="text-3xl tracking-tight text-gray-900 sm:text-3xl">
                          C&apos;est l&apos;équivalent de...
                        </h2>
                      </div>
                      {isLoading ? (
                        <Loading />
                      ) : (
                        <div className="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
                          <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-white shadow-md p-8 sm:w-10/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
                            <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">
                              {equivalent.forest} m²
                            </p>
                            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                              <p className="text-lg font-semibold tracking-tight text-gray-900">
                                de forêt mature française
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-900 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44">
                            <p className="flex-none text-3xl font-bold tracking-tight text-white">
                              {equivalent.roundTrip}
                            </p>
                            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                              <p className="text-lg font-semibold tracking-tight text-white">
                                aller-retours Paris / New-York en avion
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-indigo-600 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
                            <p className="flex-none text-3xl font-bold tracking-tight text-white">
                              {equivalent.worldTour}
                            </p>
                            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                              <p className="text-lg font-semibold tracking-tight text-white">
                                tours du monde en voiture diesel
                              </p>
                            </div>
                          </div>
                          <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-green p-8 sm:w-10/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
                            <p className="flex-none text-3xl font-bold tracking-tight text-white">
                              {equivalent.perPerson} personnes
                            </p>
                            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                              <p className="text-lg font-semibold tracking-tight text-white">
                                à raison de 9 tCO2e / an en moyenne par Français
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <h2 className="text-3xl tracking-tight text-gray-900 sm:text-3xl mt-10">
                    Intensité carbone
                  </h2>
                  <p className="mt-5">
                    Les indicateurs d’intensité carbone permettent de rendre le bilan carbone
                    comparable à un secteur d’activité ou à d’autres entreprises. Ils sont calculés
                    en rapportant le total des émissions de gaz à effet de serre de l’entreprise à
                    un autre indicateur chiffré reflétant la taille de l’entreprise.
                  </p>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <div className="w-full flex justify-between items-center space-x-5 mt-10">
                      <div className="bg-white shadow-xl rounded-md p-10 text-center w-96">
                        <p className="text-green text-4xl font-bold">{intensity.nbKgCO2eEuro}</p>
                        <p className="text-md">kgCO2e / € C.A.</p>
                      </div>
                      <div className="bg-white shadow-xl rounded-md p-10 text-center w-96">
                        <p className="text-green text-4xl font-bold">{intensity.nbKgCO2eUnit}</p>
                        <p className="text-md">kgCO2e / {intensity.unit}.</p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mt-10">
                  <h2 className="text-3xl tracking-tight text-gray-900 sm:text-3xl mt-10">
                    Trajectoire de réduction des émissions
                  </h2>
                  <p className="mt-5">
                    Limiter le changement climatique nécessite de réduire nos émissions de gaz à
                    effet de serre dans les prochaines années. Les objectifs pour chaque entreprise
                    sont fixés par des standards internationaux en fonction du secteur d’activité.
                    Ces objectifs peuvent concerner une réduction du total des émissions de gaz à
                    effet de serre ou une réduction de l’intensité carbone de l’entreprise. Les
                    bilans carbone successifs permettent d’apprécier la trajectoire de l’entreprise
                    par rapport à ses objectifs.
                  </p>
                  <div className="w-full bg-white p-5 shadow-md flex flex-col justify-center items-center mt-10">
                    <div className="w-full">
                      {isLoading || !dataBar ? (
                        <Loading />
                      ) : (
                        <ChartBar type="bar" data={dataBar} options={optionsBar} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default observer(Footprint);
