import AppRoutes from 'AppRoutes';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import config from 'config';
import './index.css';
import { setContext } from '@apollo/client/link/context';
import { observer } from 'mobx-react';
import Loading from 'components/Loading';
import TagManager from 'react-gtm-module';
import { useEffect } from 'react';

const App = (): JSX.Element => {
  const authLink = setContext((_, { headers }) => {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers
      }
    };
  });

  // Initialize Apollo Client
  const client = new ApolloClient({
    link: authLink.concat(
      new HttpLink({
        uri: config.API_URL_GRAPHQL
      })
    ),
    cache: new InMemoryCache()
  });

  if (!client) return <Loading />;

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-TJCZG97T'
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <ApolloProvider client={client}>
      <AppRoutes />
    </ApolloProvider>
  );
};

export default observer(App);
