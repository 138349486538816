import { useQuery } from '@apollo/client';
import { useGetAllForms, useGetForm, useGetAllSectors } from './queries';
import { FilterForm } from 'queryTypes/form/form.dto';

const getAll = (options?: FilterForm) =>
  useQuery(useGetAllForms(options), {
    variables: { options },
    notifyOnNetworkStatusChange: true
  });

const getSectors = () =>
  useQuery(useGetAllSectors(), {
    notifyOnNetworkStatusChange: true
  });

const getForm = (options: FilterForm) =>
  useQuery(useGetForm(options), {
    notifyOnNetworkStatusChange: true
  });
const formService = {
  getAll,
  getSectors,
  getForm
};

export default formService;
