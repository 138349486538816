import { Fragment, ReactNode, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react';
import { Form } from 'queryTypes/form';
import { Customer } from 'queryTypes/customer';
import { Collaborator } from 'queryTypes/collaborator';

type Props = {
  children: ReactNode;
  currentForm?: Form;
  customerId?: string;
  collaborator?: Collaborator;
};

const Layout = (props: Props): JSX.Element => {
  const { children, currentForm, customerId, collaborator } = props;
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  return (
    <>
      <div className="2xl:h-screen">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img className="h-8 w-auto" src="/logo.svg" alt="Carbono" />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-96 lg:flex-col">
          <img
            src="/arbres-et-la-lutte-contre-les-changements-climatiques.png"
            alt="arbres et la lutte contre les changements climatiques"
            className="left-0 top-0 w-full h-full object-cover absolute z-0 opacity-10"
          />
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6">
            <div className="flex flex-col justify-between items-center max-w-3xl mx-auto space-y-10 mt-10 ">
              <div>
                <img className="h-8 w-auto" src="/logo.svg" alt="Carbono" />
              </div>
              {currentForm && (
                <>
                  <div>
                    <img className="h-auto" src="/arrow.svg" alt="Carbono" />
                  </div>
                  <div>
                    {currentForm.customers?.find((customer: Customer) => customer.id === customerId)
                      ?.avatarUrl && (
                      <img
                        src={
                          currentForm.customers?.find(
                            (customer: Customer) => customer.id === customerId
                          )?.avatarUrl
                        }
                        alt={
                          currentForm.customers?.find(
                            (customer: Customer) => customer.id === customerId
                          )?.name
                        }
                      />
                    )}
                    <p className="text-white text-center text-5xl mt-4">
                      {
                        currentForm.customers?.find(
                          (customer: Customer) => customer.id === customerId
                        )?.name
                      }
                    </p>
                    {collaborator && (
                      <p className="text-white text-center text-xl mt-5">{collaborator.name}</p>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="bg-gray-900 w-full">
            <p className="ml-2 text-white text-xs text-left">
              © Carbono, tous droits réservés, {new Date().getFullYear()}.
            </p>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <div className="flex justify-between items-center space-x-10">
            <img src="/logo.svg" alt="Carbono" className="h-6 w-auto" />
            {currentForm && (
              <>
                <p className="text-white text-center text-2xl">x</p>
                <p className="text-white text-center text-2xl">
                  {
                    currentForm.customers?.find((customer: Customer) => customer.id === customerId)
                      ?.name
                  }
                </p>
              </>
            )}
          </div>
        </div>

        <main className="h-full">{children}</main>
      </div>
    </>
  );
};

export default observer(Layout);
