import formStore from 'stores/Form';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from 'layout';
import ProgressBar from 'components/ProgressBar';
import EstimatedTimeBar from 'components/EstimatedTimeBar';
import { Question } from 'queryTypes/question';
import collaboratorService from 'services/api/collaborator/services';
import FormCustomerFinish from 'pages/FormCustomer/Finish';
import FormCustomerIntro from 'pages/FormCustomer/Intro';
import FormCustomerStep from 'pages/FormCustomer/Step';
import { Collaborator } from 'queryTypes/collaborator';
import Loading from 'components/Loading';

const FormCollaborator = () => {
  const { currentForm } = formStore;
  const { collaboratorId, stepPosition } = useParams<{
    collaboratorId: string;
    stepPosition: string;
  }>();

  // Query collaborator
  const {
    data: collaborator,
    error,
    loading: isLoading,
    refetch
  } = collaboratorService.getCollaborator({
    id: collaboratorId
  });

  useEffect(() => {
    if (collaborator && collaborator.collaborator.customers[0].forms) {
      formStore.init(
        collaborator.collaborator.customers[0].forms[0].questions.filter((question: Question) =>
          question.collaborators.find(
            (collaborator: Collaborator) => collaborator.id === collaboratorId
          )
        )?.length
      );
    }
  }, [collaborator]);

  useEffect(() => {
    if (stepPosition) {
      formStore.update(parseInt(stepPosition));
    }
  }, [stepPosition]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!collaborator) return 'Incorrect form. (collaborator)';
  if (!currentForm) return 'Incorrect form. (store / form)';
  if (!collaborator.collaborator.customers[0].forms) return 'Incorrect form. (form)';

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <Layout
          currentForm={collaborator.collaborator.customers[0].forms[0]}
          customerId={collaborator.collaborator.customers[0].id}
          collaborator={collaborator.collaborator}
        >
          <div className="bg-[#e6e6e6] h-screen lg:pl-96">
            <div className="bg-[#e6e6e6] py-10 px-4 sm:px-6 lg:px-20">
              <div className="flex flex-col sm:flex-row justify-between items-center">
                {!stepPosition && (
                  <div className="lg:justify-start">
                    <EstimatedTimeBar
                      totalTime={collaborator.collaborator.customers[0].forms[0].questions
                        .filter((question: Question) =>
                          question.collaborators.find(
                            (collaborator: Collaborator) => collaborator.id === collaboratorId
                          )
                        )
                        .reduce(
                          (acc: number, question: Question) => acc + question.estimatedTimeToAnswer,
                          0
                        )}
                    />
                  </div>
                )}
                {stepPosition && (
                  <div className="lg:justify-end">
                    <ProgressBar stepPosition={stepPosition ? parseInt(stepPosition) : 0} />
                  </div>
                )}
              </div>
              {!stepPosition && (
                <FormCustomerIntro
                  questions={collaborator.collaborator.customers[0].forms[0].questions
                    ?.filter((question: Question) =>
                      question.collaborators.find(
                        (collaborator: Collaborator) => collaborator.id === collaboratorId
                      )
                    )
                    ?.map((question: Question) => question)
                    .sort((a: Question, b: Question) => a.orderIndex - b.orderIndex)}
                  customerId={collaborator.collaborator.customers[0].customerId}
                  collaboratorId={collaboratorId}
                />
              )}
              {stepPosition &&
                parseInt(stepPosition) > 0 &&
                parseInt(stepPosition) <= currentForm.stepsTotal &&
                !isLoading && (
                  <FormCustomerStep
                    question={
                      collaborator.collaborator.customers[0].forms[0].questions?.filter(
                        (question: Question) =>
                          question.collaborators.find(
                            (collaborator: Collaborator) => collaborator.id === collaboratorId
                          )
                      )[parseInt(stepPosition) - 1]
                    }
                    customerId={collaborator.collaborator.customers[0].id}
                    refetch={refetch}
                    isLoading={isLoading}
                    collaborator={collaborator.collaborator}
                  />
                )}
              {stepPosition && parseInt(stepPosition) > currentForm.stepsTotal && (
                <FormCustomerFinish
                  customerId={collaborator.collaborator.customers[0].id}
                  collaboratorId={collaboratorId}
                />
              )}
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default observer(FormCollaborator);
