import formStore from 'stores/Form';
import { Question } from 'queryTypes/question';
import Pagination from 'components/Pagination';
import { Type } from 'enums/type';
import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useCreateAnswer, useUpdateAnswer } from 'services/api/answer/queries';
import { observer } from 'mobx-react';
import Loading from 'components/Loading';
import FormCustomerStepInput from './Input';
import FormCustomerStepBoolean from './Boolean';
import FormCustomerStepMultipleChoices from './MultipleChoices';
import FormCustomerStepSheet from './Sheet';
import { Collaborator } from 'queryTypes/collaborator';
import {
  AtSymbolIcon,
  HomeIcon,
  InformationCircleIcon,
  PhoneIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/outline';
import { Tooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import SlideOver from 'components/SlideOver';

type Props = {
  question: Question;
  customerId: string;
  refetch: () => void;
  isLoading: boolean;
  collaborator?: Collaborator | undefined;
};

const FormCustomerStep = (props: Props) => {
  const navigate = useNavigate();
  const { question, customerId, refetch, isLoading, collaborator } = props;
  const { currentForm } = formStore;

  if (!currentForm) return null;
  const answer =
    customerId && collaborator === null
      ? question?.answers?.find((answer) => answer.customerId === customerId)
      : question?.answers?.find((answer) => answer.collaboratorId === collaborator?.id);

  const [createAnswer, { loading: isLoadingCreate }] = useMutation(useCreateAnswer);
  const [updateAnswer, { loading: isLoadingUpdate }] = useMutation(useUpdateAnswer);

  const [valueInput, setValueInput] = useState<string | number | readonly string[] | undefined>('');
  const [urlCsvFile, setUrlCsvFile] = useState<string | undefined>(answer?.csvUrl ?? undefined);

  const [openSlideOver, setOpenSlideOver] = useState<boolean>(false);
  const handleChange = useCallback(
    (
      value?: string | number | readonly string[] | undefined,
      answerId?: string,
      type?: Type.STRING | Type.INTEGER | Type.BOOLEAN | Type.MULTIPLE_CHOICES | Type.SHEET,
      urlCsvFile?: string | undefined
    ) => {
      if (isLoadingCreate) return;
      if (isLoadingUpdate) return;
      if (!value) return;

      if (answerId) {
        if (answer?.value === value) {
          formStore.update(currentForm.stepPosition + 1);
          setValueInput('');
          return;
        }

        if (type !== Type.SHEET) {
          updateAnswer({
            variables: {
              answerId: answerId,
              answer: {
                value: value,
                sheet: null,
                collaboratorId: collaborator?.id ?? null,
                customerId: customerId,
                csvUrl: urlCsvFile
              }
            },
            onCompleted: (data) => {
              if (data && !data.updateAnswer.errors) {
                formStore.update(currentForm.stepPosition + 1);
                refetch();
                setValueInput('');
                setUrlCsvFile(undefined);
              }
            }
          });
        } else {
          updateAnswer({
            variables: {
              answerId: answerId,
              answer: {
                sheet: value,
                value: null,
                collaboratorId: collaborator?.id ?? null,
                customerId: customerId,
                csvUrl: urlCsvFile
              }
            },
            onCompleted: (data) => {
              if (data && !data.updateAnswer.errors) {
                formStore.update(currentForm.stepPosition + 1);
                refetch();
                setValueInput('');
                setUrlCsvFile(undefined);
              }
            }
          });
        }
      } else {
        if (type !== Type.SHEET) {
          createAnswer({
            variables: {
              answer: {
                questionId: question?.id,
                customerId: customerId,
                value: value,
                collaboratorId: collaborator?.id ?? null,
                csvUrl: urlCsvFile
              }
            },
            onCompleted: (data) => {
              if (data && !data.createAnswer.errors) {
                formStore.update(currentForm.stepPosition + 1);
                refetch();
                setValueInput('');
                setUrlCsvFile(undefined);
              }
            }
          });
        } else {
          createAnswer({
            variables: {
              answer: {
                questionId: question?.id,
                customerId: customerId,
                sheet: value,
                collaboratorId: collaborator?.id ?? null,
                csvUrl: urlCsvFile
              }
            },
            onCompleted: (data) => {
              if (data && !data.createAnswer.errors) {
                formStore.update(currentForm.stepPosition + 1);
                refetch();
                setValueInput('');
                setUrlCsvFile(undefined);
              }
            }
          });
        }
      }
      setValueInput('');
    },
    [answer, valueInput, urlCsvFile]
  );

  useEffect(() => {
    if (answer) {
      if (answer.csvUrl && !urlCsvFile) {
        setUrlCsvFile(answer.csvUrl);
      }
      if (question.sheetTemplate && question.answerType === Type.SHEET) {
        setValueInput(answer.sheet ?? '');
      } else {
        setValueInput(answer.value);
      }
    }
  }, [answer, question, urlCsvFile]);

  return (
    <div>
      <SlideOver isOpen={openSlideOver} setIsOpen={setOpenSlideOver} title="Besoin d'aide ?">
        <div className="flex flex-col space-y-5">
          <div>
            <img src="https://www.carbono.eco/images/thumbnail-carbono-desk.jpg" alt="Carbono" />
          </div>
          <div>
            <p>Contactez-nous par téléphone au</p>
            <div className="flex flex-row justify-start items-center space-x-3">
              <PhoneIcon className="h-5" />
              <div className="text-2xl font-bold">06 24 03 37 96</div>
            </div>
          </div>
          <div>
            <p>Ou contactez-nous par email</p>
            <div className="flex flex-row justify-start items-center space-x-3">
              <AtSymbolIcon className="h-5" />
              <div className="text-2xl font-bold">
                <a href="mailto:yannick.desmarest@carbono.eco">hello@carbono.eco</a>
              </div>
            </div>
          </div>
        </div>
      </SlideOver>
      <div className="flex justify-start items-center mt-20">
        <div>
          <h3 className="text-4xl font-bold">{question?.text}</h3>
        </div>
        <div>
          {question?.helpMessage?.length > 0 && (
            <>
              <InformationCircleIcon
                className="w-6 h-6 ml-2 text-green"
                data-tooltip-id="helpMessage-tooltip"
                data-tooltip-html={question?.helpMessage}
                data-tooltip-place="right"
                data-tooltip-variant="dark"
              />
              <Tooltip id="helpMessage-tooltip" />
            </>
          )}
        </div>
      </div>
      {isLoading ? (
        <Loading spinner />
      ) : (
        <>
          {question?.answerType === Type.STRING && (
            <FormCustomerStepInput
              setValueInput={setValueInput}
              defaultValue={valueInput}
              placeholder="Votre réponse"
              type="text"
            />
          )}
          {question?.answerType === Type.INTEGER && (
            <FormCustomerStepInput
              setValueInput={setValueInput}
              defaultValue={valueInput}
              placeholder="Votre réponse"
              type="number"
            />
          )}
          {question?.answerType === Type.BOOLEAN && (
            <FormCustomerStepBoolean setValueInput={setValueInput} defaultValue={valueInput} />
          )}
          {question?.answerType === Type.MULTIPLE_CHOICES && (
            <FormCustomerStepMultipleChoices
              setValueInput={setValueInput}
              defaultValue={valueInput}
              question={question}
            />
          )}
          {question?.answerType === Type.SHEET && (
            <FormCustomerStepSheet
              setValueInput={setValueInput}
              answer={answer}
              question={question}
              setUrlCsvFile={setUrlCsvFile}
              urlCsvFile={urlCsvFile}
            />
          )}
          <Pagination
            customerId={customerId}
            collaboratorId={collaborator?.id}
            onNextSubmit={handleChange}
            answerId={answer?.id}
            value={valueInput}
            type={question?.answerType}
            onValue={setValueInput}
            urlCsvFile={urlCsvFile}
            onUrlCsvFile={setUrlCsvFile}
          />

          <div className="mt-10">
            <div className="flex flex-row justify-end items-center space-x-5 mt-10">
              <div>
                <button type="button" onClick={() => setOpenSlideOver(true)} className="text-green">
                  <span className="font-normal flex flex-row items-center justify-between">
                    <QuestionMarkCircleIcon className="inline-block w-5 h-5 mr-2" />
                    Besoin d&apos;aide
                  </span>
                </button>
              </div>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    formStore.reset(currentForm.stepsTotal);
                    navigate(
                      `/forms/${collaborator?.id ? `collaborators/${collaborator.id}` : customerId}`
                    );
                  }}
                  className="text-green"
                >
                  <span className="font-normal flex flex-row items-center justify-between">
                    <HomeIcon className="inline-block w-5 h-5 mr-2" />
                    Retour au sommaire
                  </span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(FormCustomerStep);
