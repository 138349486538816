import formStore from 'stores/Form';

type Props = {
  stepPosition: number;
};

const ProgressBar = (props: Props) => {
  const { stepPosition } = props;
  const { currentForm } = formStore;
  if (!currentForm) return null;

  const percentage =
    ((stepPosition > currentForm.stepsTotal ? currentForm.stepsTotal : stepPosition) /
      currentForm.stepsTotal) *
    100;
  return (
    <div className="w-max lg:w-48">
      <p>
        {stepPosition > currentForm.stepsTotal ? currentForm.stepsTotal : stepPosition} sur{' '}
        {currentForm.stepsTotal} question(s)
      </p>
      <div className="h-1 w-full bg-gray-300">
        <div style={{ width: `${percentage}%` }} className="h-full bg-green"></div>
      </div>
    </div>
  );
};

export default ProgressBar;
