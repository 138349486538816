import { SheetAnswers, SheetValues } from '..';
import FormCustomerStepSheetTableLine from './Line';

type Props = {
  onChangeSheetTemplate: (value: string, index: number, indexValue: number) => void;
  onDeleteSheetTemplate: (index: number) => void;
  sheetQuestion: SheetValues[];
  sheetAnswer: SheetAnswers[][];
};

const FormCustomerStepSheetTable = (props: Props) => {
  const { onChangeSheetTemplate, onDeleteSheetTemplate, sheetQuestion, sheetAnswer } = props;

  return (
    <table className="min-w-full divide-y divide-gray-900 mb-28 mt-10">
      <thead>
        <tr className="divide-x divide-gray-900">
          <th></th>
          {sheetQuestion?.map((sheet: SheetValues, index: number) => (
            <th
              key={index}
              scope="col"
              className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              {sheet.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-900">
        {sheetAnswer?.map((sheets: SheetAnswers[], index: number) => (
          <FormCustomerStepSheetTableLine
            key={index}
            onChangeSheetTemplate={onChangeSheetTemplate}
            onDeleteSheetTemplate={onDeleteSheetTemplate}
            sheetQuestion={sheetQuestion}
            sheetAnswer={sheets}
            index={index}
          />
        ))}
      </tbody>
    </table>
  );
};

export default FormCustomerStepSheetTable;
