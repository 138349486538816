import { gql } from '@apollo/client';
import { FilterAnswer } from 'queryTypes/answer';

export const useGetAllAnswers = (options: FilterAnswer) => gql`
  query getAnswers {
    answers(filter: "${options.customerId ? `customer_id == '${options.customerId}'` : ''} ${
  options.questionId ? `&& question_id == '${options.questionId}'` : ''
}") {
      id
      value
      csvUrl
      questionId
      customerId
      collaboratorId
      createdAt
      updatedAt
    }
  }
`;

export const useGetAnswer = (options: FilterAnswer) => gql`
  query getAnswer {
    answer(id: "${options.id}") {
      id
      value
      questionId
      customerId
      collaboratorId
      createdAt
      updatedAt
    }
  }
`;

export const useCreateAnswer = gql`
  mutation createAnswer($answer: AnswerInputType!) {
    createAnswer(attributes: $answer) {
      answer {
        id
        value
      }
      errors
    }
  }
`;

export const useUpdateAnswer = gql`
  mutation updateAnswer($answerId: AnswerId!, $answer: AnswerInputType!) {
    updateAnswer(id: $answerId, attributes: $answer) {
      answer {
        id
      }
      errors
    }
  }
`;
