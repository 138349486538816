import { PossibleAnswer } from 'queryTypes/possibleAnswer';
import { Question } from 'queryTypes/question';

type Props = {
  setValueInput: (value: string) => void;
  defaultValue: string | number | readonly string[] | undefined;
  question: Question;
};

const FormCustomerStepMultipleChoices = (props: Props) => {
  const { setValueInput, defaultValue, question } = props;

  return (
    <div className="mt-10 flex flex-col justify-between items-center space-y-5">
      {question?.possibleAnswers.map((possibleAnswer: PossibleAnswer, index: number) => (
        <button
          key={index}
          type="button"
          className={`px-10 py-4 font-medium border border-gray-300  hover:bg-green hover:text-white uppercase w-full rounded-md ${
            possibleAnswer.value == defaultValue ? 'bg-green text-white' : 'bg-white text-black'
          }`}
          onClick={() => {
            setValueInput(possibleAnswer.value);
          }}
        >
          {possibleAnswer.label}
        </button>
      ))}
    </div>
  );
};

export default FormCustomerStepMultipleChoices;
