import { TrashIcon } from '@heroicons/react/24/outline';
import { SheetAnswers, SheetValues } from '../../';
import FormCustomerStepSheetTableLineItem from './Item';

type Props = {
  onChangeSheetTemplate: (value: string, index: number, indexValue: number) => void;
  onDeleteSheetTemplate: (index: number) => void;
  sheetQuestion: SheetValues[];
  sheetAnswer: SheetAnswers[];
  index: number;
};

const FormCustomerStepSheetTableLine = (props: Props) => {
  const { onChangeSheetTemplate, onDeleteSheetTemplate, sheetQuestion, sheetAnswer, index } = props;

  return (
    <tr className="divide-x divide-gray-900">
      {index > 0 ? (
        <td>
          <button
            type="button"
            className="rounded-full bg-red-600 p-2 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            onClick={() => onDeleteSheetTemplate(index)}
          >
            <TrashIcon className="h-5 w-5" />
          </button>
        </td>
      ) : (
        <td></td>
      )}
      {sheetQuestion?.map((sheetValue: SheetValues, indexValue: number) => (
        <FormCustomerStepSheetTableLineItem
          key={indexValue}
          onChangeSheetTemplate={onChangeSheetTemplate}
          sheetQuestion={sheetValue}
          sheetAnswer={sheetAnswer}
          index={index}
          indexValue={indexValue}
        />
      ))}
    </tr>
  );
};

export default FormCustomerStepSheetTableLine;
