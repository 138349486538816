import { gql } from '@apollo/client';
import { FilterForm } from 'queryTypes/form/form.dto';

export const useGetAllForms = (options?: FilterForm) => gql`
  query getForms {
    forms(filter: "${options?.customerId ? `customers.id == '${options?.customerId}'` : ``}"${
  options?.orderByField ? `, orderBy: "${options?.orderByField} ${options?.orderByDirection}"` : ``
}) {
      id
      sector
      questions {
        id
        orderIndex
        text
        helpMessage
        answerType
        estimatedTimeToAnswer
        sheetFormula
        sheetTemplate
        possibleAnswers {
          id
          value
          label
        }
        answers {
          id
          value
          sheet
          customerId
          csvUrl
        }
      }
      customers {
        id
        name
        avatarUrl
      }
      stats {
        id
        camembert
        bar
        equivalent
        intensity
        year
      }
    }
  }
`;

export const useGetAllSectors = () => gql`
  query getSectors {
    sectors
  }
`;

export const useGetForm = (options: FilterForm) => gql`
  query getForm {
    form(id: "${options.id}") {
      id
      sector
      questions {
        id
      }
    }
  }
`;
