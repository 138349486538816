import { runInAction, makeAutoObservable } from 'mobx';

type FormStoreType = {
  stepPosition: number;
  stepsTotal: number;
};

const initialForm: FormStoreType | null = (() => {
  try {
    return JSON.parse(window.localStorage.getItem('currentForm') || 'null');
  } catch (error) {
    return null;
  }
})();

class FormStore {
  currentForm: FormStoreType | null = initialForm
    ? {
        stepPosition: initialForm.stepPosition,
        stepsTotal: initialForm.stepsTotal
      }
    : null;

  constructor() {
    makeAutoObservable(this);
  }

  initForm(stepsTotal: number) {
    window.localStorage.setItem(
      'currentForm',
      JSON.stringify({
        ...{
          stepPosition: 0,
          stepsTotal
        }
      })
    );

    runInAction(() => {
      this.currentForm = {
        stepPosition: 0,
        stepsTotal
      };
    });
  }

  updateForm(stepPosition: number, currentForm: FormStoreType) {
    window.localStorage.setItem(
      'currentForm',
      JSON.stringify({
        ...{
          stepPosition,
          stepsTotal: currentForm.stepsTotal
        }
      })
    );

    runInAction(() => {
      this.currentForm = {
        stepPosition,
        stepsTotal: currentForm.stepsTotal
      };
    });
  }

  init(stepsTotal: number) {
    if (this.currentForm?.stepsTotal === stepsTotal) return;
    this.initForm(stepsTotal);
  }

  reset(stepsTotal: number) {
    this.initForm(stepsTotal);
  }

  update(stepPosition: number) {
    if (!this.currentForm) return;
    this.updateForm(stepPosition, this.currentForm);
  }
}

export default new FormStore();
export { FormStore };
