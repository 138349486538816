import formStore from 'stores/Form';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';
import { useCallback } from 'react';
import { observer } from 'mobx-react';
import { Type } from 'enums/type';
import { useNavigate } from 'react-router-dom';

type Props = {
  customerId: string;
  collaboratorId?: string;
  onNextSubmit?: (
    value?: string | number | readonly string[] | undefined,
    answerId?: string,
    type?: Type.STRING | Type.INTEGER | Type.BOOLEAN | Type.MULTIPLE_CHOICES | Type.SHEET,
    urlCsvFile?: string
  ) => void;
  answerId?: string;
  value?: string | number | readonly string[] | undefined;
  onValue?: (value?: string | number | readonly string[] | undefined) => void;
  type?: Type.STRING | Type.INTEGER | Type.BOOLEAN | Type.MULTIPLE_CHOICES | Type.SHEET;
  urlCsvFile?: string;
  onUrlCsvFile?: (value: string) => void;
};

const Pagination = (props: Props) => {
  const navigate = useNavigate();
  const {
    customerId,
    collaboratorId,
    onNextSubmit,
    answerId,
    value,
    onValue,
    type,
    onUrlCsvFile,
    urlCsvFile
  } = props;
  const { currentForm } = formStore;

  if (!currentForm) return null;

  const handleChange = useCallback(() => {
    onNextSubmit && onNextSubmit(value, answerId, type, urlCsvFile);
    onValue && onValue('');
    onUrlCsvFile && onUrlCsvFile('');
    navigate(
      `/forms/${collaboratorId ? `collaborators/${collaboratorId}` : customerId}/${
        currentForm.stepPosition + 1
      }`
    );
  }, [onNextSubmit, value, answerId, type]);

  return (
    <div className="mt-10 flex flex-row justify-between items-center">
      <div className="flex justify-between items-center w-full">
        <button
          type="button"
          className={`justify-start inline-flex px-10 py-4 font-medium text-white border border-gray-300 bg-green rounded-full uppercase ${
            currentForm.stepPosition <= 1 ? 'opacity-0' : 'opacity-100'
          }`}
          onClick={() => {
            onValue && onValue('');
            onUrlCsvFile && onUrlCsvFile('');
            formStore.update(currentForm.stepPosition - 1);
            navigate(
              `/forms/${collaboratorId ? `collaborators/${collaboratorId}` : customerId}/${
                currentForm.stepPosition - 1
              }`
            );
          }}
          disabled={currentForm.stepPosition <= 1}
        >
          <ArrowLeftIcon className="inline-block w-5 h-5 mr-2" />
          Précédent
        </button>
        <button
          type="button"
          className={`justify-start inline-flex px-10 py-4 font-medium border border-gray-300 rounded-full uppercase ${
            currentForm.stepPosition >= currentForm.stepsTotal + 1 ? 'opacity-0' : 'opacity-100'
          } ${value ? 'bg-green text-white' : 'bg-white text-black cursor-not-allowed'}`}
          onClick={() =>
            currentForm.stepPosition <= currentForm.stepsTotal + 1 && value && handleChange()
          }
          disabled={currentForm.stepPosition >= currentForm.stepsTotal + 1 && !value}
        >
          Suivant
          <ArrowRightIcon className="inline-block w-5 h-5 ml-2" />
        </button>
      </div>
    </div>
  );
};

export default observer(Pagination);
