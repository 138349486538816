import {
  ArrowRightIcon,
  BellAlertIcon,
  CheckBadgeIcon,
  ClockIcon,
  CloudArrowUpIcon,
  KeyIcon
} from '@heroicons/react/24/outline';
import Layout from 'layout';

const Home = () => {
  return (
    <Layout>
      <div className="bg-[#e6e6e6] h-screen lg:pl-96">
        <div className="bg-[#e6e6e6] py-10 px-4 sm:px-6 lg:px-20">
          <div className="flex flex-col lg:flex-row justify-between items-start px-6 lg:px-0 mt-10 space-y-20 space-x-4">
            <div className="mx-auto w-full lg:mx-0 lg:max-w-none flex flex-col space-y-4">
              <div>
                <div className="lg:pr-4">
                  <div className="lg:max-w-lg">
                    <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                      Votre bilan carbone optimisé
                    </h1>
                    <p className="mt-6 text-xl leading-8 text-gray-700">
                      Nous vous préparons un questionnaire optimisé et exclusif à votre secteur
                      d&apos;activité.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <div className="lg:pr-4">
                  <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
                    <ul role="list" className="mt-8 space-y-8 text-gray-600">
                      <li className="flex gap-x-3">
                        <CloudArrowUpIcon
                          className="mt-1 h-5 w-5 flex-none text-green"
                          aria-hidden="true"
                        />
                        <span>
                          <strong className="font-semibold text-gray-900">
                            Toutes vos réponses sont automatiquement sauvegardées,
                          </strong>{' '}
                          vous pouvez à tout moment quitter cette page et revenir plus tard
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <ClockIcon
                          className="mt-1 h-5 w-5 flex-none text-green"
                          aria-hidden="true"
                        />
                        <span>
                          <strong className="font-semibold text-gray-900">
                            Soyez le plus précis possible,
                          </strong>{' '}
                          prenez votre temps
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <CheckBadgeIcon
                          className="mt-1 h-5 w-5 flex-none text-green"
                          aria-hidden="true"
                        />
                        <span>
                          <strong className="font-semibold text-gray-900">
                            Lorsque vous avez terminé,
                          </strong>{' '}
                          assurez-vous que toutes vos informations sont correctes
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <KeyIcon className="mt-1 h-5 w-5 flex-none text-green" aria-hidden="true" />
                        <span>
                          Toutes vos données sont{' '}
                          <strong className="font-semibold text-gray-900">sécurisées</strong>
                        </span>
                      </li>
                      <li className="flex gap-x-3">
                        <BellAlertIcon
                          className="mt-1 h-5 w-5 flex-none text-green"
                          aria-hidden="true"
                        />
                        <span>
                          <strong className="font-semibold text-gray-900">
                            Vous serez automatiquement avertis
                          </strong>{' '}
                          lorsque votre Bilan carbone sera validé
                        </span>
                      </li>
                    </ul>
                    <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">
                      Vous êtes prêts ?
                    </h2>
                    <p className="mt-6">
                      Si vous souhaitez être obtenir votre questionnaire pour votre Bilan carbone,
                      cliquez sur &quot;Je suis prêt&quot;
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:fixed lg:bottom-2 mt-5 mb-5 lg:right-0 lg:px-20 px-10 w-96">
              <a
                href="mailto:support@carbono.eco?subject=Premier contact&body=Bonjour, je souhaite obtenir mon Bilan carbone."
                className="flex items-center px-10 py-4 text-white bg-green rounded-full uppercase"
              >
                Je suis prêt
                <ArrowRightIcon className="inline-block w-5 h-5 ml-2" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
