import formStore from 'stores/Form';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import formService from 'services/api/forms/services';
import Layout from 'layout';
import ProgressBar from 'components/ProgressBar';
import FormCustomerIntro from './Intro';
import FormCustomerStep from './Step';
import FormCustomerFinish from './Finish';
import EstimatedTimeBar from 'components/EstimatedTimeBar';
import { Question } from 'queryTypes/question';

const FormCustomer = () => {
  const { currentForm } = formStore;
  const { customerId, stepPosition } = useParams<{ customerId: string; stepPosition: string }>();

  // Query forms
  const {
    data: form,
    error,
    refetch,
    loading: isLoading
  } = formService.getAll({
    customerId: customerId
  });

  useEffect(() => {
    if (form && form.forms[0]) {
      formStore.init(form.forms[0].questions.length);
    }
  }, [form]);

  useEffect(() => {
    if (stepPosition) {
      formStore.update(parseInt(stepPosition));
    }
  }, [stepPosition]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!customerId) return 'Incorrect form. (customer)';
  if (!currentForm) return 'Incorrect form. (store / form)';
  if (form && !form.forms[0]) return 'Incorrect form. (form)';

  return (
    <>
      <Layout currentForm={form?.forms[0]} customerId={customerId}>
        <div className="bg-[#e6e6e6] h-screen lg:pl-96">
          <div className="bg-[#e6e6e6] py-10 px-4 sm:px-6 lg:px-20">
            <div className="flex flex-col sm:flex-row justify-between items-center">
              {!stepPosition && (
                <div className="lg:justify-start">
                  <EstimatedTimeBar
                    totalTime={form?.forms[0].questions.reduce(
                      (acc: number, question: Question) => acc + question.estimatedTimeToAnswer,
                      0
                    )}
                  />
                </div>
              )}
              {stepPosition && (
                <div className="lg:justify-end">
                  <ProgressBar stepPosition={stepPosition ? parseInt(stepPosition) : 0} />
                </div>
              )}
            </div>
            {!stepPosition && (
              <FormCustomerIntro
                questions={form?.forms[0].questions
                  ?.map((question: Question) => question)
                  .sort((a: Question, b: Question) => a.orderIndex - b.orderIndex)}
                customerId={customerId}
              />
            )}
            {stepPosition &&
              parseInt(stepPosition) > 0 &&
              parseInt(stepPosition) <= currentForm.stepsTotal &&
              !isLoading && (
                <FormCustomerStep
                  question={form.forms[0].questions?.find(
                    (question: Question) => question.orderIndex === parseInt(stepPosition)
                  )}
                  customerId={customerId}
                  refetch={refetch}
                  isLoading={isLoading}
                />
              )}
            {stepPosition && parseInt(stepPosition) > currentForm.stepsTotal && (
              <FormCustomerFinish customerId={customerId} />
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default observer(FormCustomer);
